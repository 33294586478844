import NewsImage from '../../../assets/img/news/loteria-famosos-326x245.jpg';
import RecentNews from '../../Elements/RecentNews';
import { Row, Col } from 'reactstrap';
import { HeadFC } from 'gatsby';
import React from "react";

import './index.scss';

const Container = ({ news }: any) => {
  const createdAt = news?.createdAt && new Date(news?.createdAt).toLocaleDateString() || '';
  const tags = news?.tags?.map((tag: any) => tag.name).join(', ');
  const caption = news?.caption;
  const content = news?.content;
  const banner = news?.banner;

  return (
    <Row className="pricipal-news-container">
      <Col md={8} className="content-list">
        <h1>{caption}</h1>

        <div className="news-info">
          <i className="bi bi-clock-fill"></i> <span>{createdAt}</span>
          {tags && <><i className="bi bi-tags-fill"></i> <span>{tags}</span></>}
        </div>

        <div className="news-banner">
          <img src={banner} alt="" />
        </div>

        <div
          className="news-content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>

        <div className="news-tags">
          <span className="active">
            <i className="bi bi-tags-fill"></i>
          </span>
          {tags && tags.map((tag: string) => (
            <span>
              {tag}
            </span>
          ))}
        </div>

        <div className="news-forward-and-next">

        </div>
      </Col>
      <Col md={4}>
        <RecentNews />
      </Col>
    </Row>
  );
}

export default Container;
