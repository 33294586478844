import NewsContainer from "../../components/Common/NewsContainer";
import Footer from "../../components/Common/Footer";
import Header from "../../components/Common/Header";
import Menu from "../../components/Common/Menu";
import { SEO } from '../../components/SEO';
import React from "react";

import 'bootstrap-icons/font/bootstrap-icons.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/global.scss';

const NewsTemplatePage = ({ pageContext }: any) => {
  return (
    <main>
      <Header />
      <Menu />
      <NewsContainer
        news={pageContext.news}
      />
      <Footer />
    </main>
  );
}

export default NewsTemplatePage;

export const Head = () => (
  <SEO>
    <link id="icon" rel="icon" href="icon-specific-for-this-page" />
  </SEO>
);
